import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
} from "react-native";
import LatoText from "../../components/LatoText";
import useImage from "../../hooks/image";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import useGeneralizeColor from "../../hooks/generalizeColor";
import { useConference } from "../../hooks/conference";

const SanduicheMenu = ({ onMenuItemPress, onClose }) => {
  const { loading: bgL, error: bgE, image: bg } = useImage("bg.png");
  const navigation = useNavigation();
  const route = useRoute();
  const [selectedItem, setSelectedItem] = useState(route.name);
  const { menuColors } = useGeneralizeColor();
  const { deselectConference } = useConference();

  const handlePress = (screen) => {
    setSelectedItem(screen);
    navigation.navigate(screen);
    onMenuItemPress(screen);
  };

  const getLinkStyle = (screen) => {
    return [
      styles.LinksText,
      {
        color:
          screen === selectedItem ? menuColors?.selectedItem : menuColors?.item,
      },
    ];
  };

  useEffect(() => {
    setSelectedItem(route.name);
  }, [route.name]);

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.closeButton} onPress={onClose}>
        <Ionicons name="close" size={30} color="white" />
      </TouchableOpacity>
      <ImageBackground
        source={bg}
        style={styles.imageBg}
        imageStyle={{ resizeMode: "cover" }}
      >
        <View style={styles.menuContainer}>
          <TouchableOpacity onPress={() => handlePress("Schedule")}>
            <LatoText style={getLinkStyle("Schedule")}>Cronograma</LatoText>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePress("InitiativesBoard")}>
            <LatoText style={getLinkStyle("InitiativesBoard")}>
              Iniciativas
            </LatoText>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePress("Questions")}>
            <LatoText style={getLinkStyle("Questions")}>Perguntas</LatoText>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePress("Partners")}>
            <LatoText style={getLinkStyle("Partners")}>Parceiros</LatoText>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePress("ContactsBook")}>
            <LatoText style={getLinkStyle("ContactsBook")}>Contatos</LatoText>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePress("Leaderboard")}>
            <LatoText style={getLinkStyle("Leaderboard")}>Ranking</LatoText>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePress("Profile")}>
            <LatoText style={getLinkStyle("Profile")}>Perfil</LatoText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              deselectConference();
              navigation.navigate("Main");
            }}
          >
            <LatoText style={getLinkStyle("Main")}>Todas Conferências</LatoText>
          </TouchableOpacity>
        </View>
      </ImageBackground>
    </View>
  );
};

export default SanduicheMenu;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  imageBg: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  menuContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    gap: 30,
  },
  LinksText: {
    fontSize: 20,
    fontWeight: "600",
  },
  selectedText: {},
  closeButton: {
    position: "absolute",
    top: 25,
    right: 20,
    zIndex: 1000,
  },
});
